<template>
  <section v-scroll-reveal="{ delay: 500 }" id="education" class="section">
    <b-container>
      <div class="section-title">
        <h4 class="text-uppercase text-center">
          <!-- HIER EIN BILD EINFÜGEN -->
          <font-awesome-icon :icon="'book'" />
          Education
        </h4>
      </div>

      <div id="timeline-education" v-if="!loading">
        <ExperienceCard
          v-for="education in educations"
          :key="education.id"
          :dotletter="education.dotletter"
          :title="education.title"
          :company="education.company"
          :time="education.time"
          :text="education.text"
        />
      </div>
    </b-container>
  </section>
</template>

<script>
import ExperienceCard from "../components/ExperienceCard.vue";
import { HTTP } from "../common/http-common.js";

export default {
  name: "Education",
  components: {
    ExperienceCard,
  },
  data() {
    return {
      educations: [],
      loading: true,
    };
  },
  mounted() {
    HTTP.get("education")
      .then((response) => {
        this.educations = response.data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
#education {
  background-color: var(--background-secondary);
}

/* Styling für "Strich" */
#timeline-education:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 23px;
  left: 50%;
  height: 99.5% !important;
  width: 4px;
  opacity: 0.3;
  background: var(--maincolor);
}

@media only screen and (min-width: 768px) {
  #timeline-education::before {
    left: 50%;
    margin-left: -2px;
    height: 72% !important;
    opacity: 0.4;
    background: var(--maincolor);
  }
}
</style>
