<template>
  <section v-scroll-reveal="{ delay: 500 }" id="experience" class="section">
    <b-container>
      <div class="section-title">
        <h4 class="text-uppercase text-center">
          <font-awesome-icon :icon="'binoculars'" />
          Experience
        </h4>
      </div>

      <div id="timeline-experience" v-if="!loading">
        <ExperienceCard
          v-for="experience in experiences"
          :key="experience.id"
          :dotletter="experience.dotletter"
          :title="experience.title"
          :company="experience.company"
          :time="experience.time"
          :text="experience.text"
        />
      </div>
    </b-container>
  </section>
</template>

<script>
import ExperienceCard from "../components/ExperienceCard.vue";
import { HTTP } from "../common/http-common.js";

export default {
  name: "Experience",
  components: {
    ExperienceCard,
  },
  data() {
    return {
      experiences: [],
      loading: true,
    };
  },
  mounted() {
    HTTP.get("experience")
      .then((response) => {
        this.experiences = response.data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
#experience {
  background-color: var(--background-secondary);
}

/* Styling für "Strich" */
#timeline-experience::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 23px;
  left: 50%;
  height: 99.5% !important;
  width: 4px;
  opacity: 0.3;
  background: var(--maincolor);
}
@media only screen and (min-width: 768px) {
  #timeline-experience::before {
    left: 50%;
    margin-left: -2px;
    height: 60.1% !important;
    opacity: 0.4;
    background: var(--maincolor);
  }
}
</style>
