<template>
  <b-container v-scroll-reveal="{ delay: 500 }">
    <b-card class="py-0 nav-responsive">
      <b-col>
        <NavigationBase />
      </b-col>
    </b-card>
  </b-container>
</template>

<script>
import NavigationBase from "@/components/NavigationBase.vue";
export default {
  name: "Navigation",
  components: { NavigationBase },
  methods: {
    // Convenience method to scroll a heading into view.
    // Not required for scrollspy to work
    scrollIntoView(event) {
      event.preventDefault();
      const href = event.target.getAttribute("href");
      const el = href ? document.querySelector(href) : null;
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop;
      }
    },
  },
};
</script>

<style>
.navbar-nav .nav-item .nav-link,
.navbar .navbar-toggler {
  color: var(--maincolor) !important;
  border-color: var(--maincolor) !important;
}
.navbar-nav .nav-item .nav-link {
  height: 5rem;
}

@media only screen and (max-width: 767px) {
  .nav-responsive {
    visibility: hidden;
    height: 0;
  }
}
</style>
