<template>
  <div>
    <b-button id="menuToggle" v-b-toggle.sidebar>
      <b-icon icon="arrow-bar-right"></b-icon>
    </b-button>
    <b-sidebar
      id="sidebar"
      title="Sidebar with backdrop"
      no-header
      backdrop
      shadow
      class="sidebar"
    >
      <div>
        <h4 id="sidebar-header">
          <b-button id="menuToggleSidebar" v-b-toggle.sidebar>
            <b-icon icon="arrow-bar-left"></b-icon>
          </b-button>
          <b-icon icon="map"></b-icon>
          Navigation
        </h4>

        <b-nav v-b-scrollspy>
          <b-nav-item @click="goto('skills')"
            ><b-icon icon="bar-chart-steps"></b-icon> Skill</b-nav-item
          >
          <b-nav-item @click="goto('education')"
            ><b-icon icon="book"></b-icon> Education</b-nav-item
          >
          <b-nav-item @click="goto('experience')"
            ><b-icon icon="binoculars"></b-icon> Experience</b-nav-item
          >
          <b-nav-item @click="goto('interest')">
            <b-icon icon="star-fill"></b-icon> Interests</b-nav-item
          >
          <b-nav-item @click="goto('contact')"
            ><b-icon icon="bookmarks"></b-icon> Contact</b-nav-item
          >
        </b-nav>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  components: {},
  methods: {
    goto(id) {
      var element = document.getElementById(id);
      var top = element.offsetTop;
      window.scrollTo({ left: 0, top, behavior: "smooth" });
    },
  },
};
</script>

<style>
#sidebar-header {
  color: #262525;
  background-color: var(--maincolor);
  font-weight: 700;
  padding: 30px 20px;
  margin: 0;
  text-shadow: 1px 1px 4px#262525;
}
#sidebar {
  background-color: #e3e3e3 !important;
}

#sidebar .nav {
  display: list-item !important;
}

#sidebar .nav .nav-link {
  border-bottom: 2px solid;
  border-bottom-color: var(--maincolor);
  font-weight: bold;
  padding: 15px 20px;
}

#menuToggle:hover,
#menuToggleSidebar:hover {
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease-in-out;
}

#menuToggleSidebar {
  right: -52px;
}
#menuToggle {
  left: 20px;
}
#menuToggle,
#menuToggleSidebar {
  color: var(--maincolor);
  position: fixed;
  top: 20px;
  z-index: 11;
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid rgba(93, 92, 92, 0.52);
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}
</style>
