<template>
  <div id="app" v-cloak>
    <Sidebar />
    <Index />

    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view /> -->

    <ScrollTopArrow />
    <Footer />
  </div>
</template>

<script>
import Index from "@/pages/Index.vue";
import Footer from "@/components/Footer.vue";
import ScrollTopArrow from "@/components/ScrollTopArrow.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: {
    Index,
    Footer,
    ScrollTopArrow,
    Sidebar,
  },
};
</script>

<style>
[v-cloak] {
  display: none;
}
</style>
