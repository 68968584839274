<template>
  <ScrollTopGeneric>
    <a>
      <font-awesome-icon :icon="['fas', 'angle-up']" />
    </a>
  </ScrollTopGeneric>
</template>

<script>
import ScrollTopGeneric from "./ScrollTopGeneric";
export default {
  components: {
    ScrollTopGeneric,
  },
};
</script>

<style scoped></style>
