<template>
  <section v-scroll-reveal="{ delay: 500 }" id="contact">
    <b-container>
      <div class="section-title">
        <h4 class="text-uppercase text-center">
          <font-awesome-icon :icon="'bookmark'" /> Contact
        </h4>
      </div>
      <b-row>
        <b-col md="12">
          <div id="contact-card">
            <b-alert
              id="succesMsg"
              variant="success"
              dismissible
              :show="dismissCountDown"
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
              >Formular erfolgreich</b-alert
            >
            <b-alert id="failureMsg" variant="danger" :show="callFailed">
              Formular konnte nicht übermittelt werden. Bitte versuchen sie es
              später erneut!
            </b-alert>
            <b-card>
              <div class="card-content">
                <b-form id="contact-form" name="c-form" @submit="onSubmit">
                  <div class="input-field">
                    <b-form-input
                      id="first_name"
                      type="text"
                      class="validate"
                      name="first_name"
                      v-model="contact.first_name"
                      required
                    ></b-form-input>
                    <label for="first_name">Vorname</label>
                  </div>
                  <div class="input-field">
                    <b-form-input
                      id="last_name"
                      type="text"
                      class="validate"
                      name="last_name"
                      v-model="contact.last_name"
                      required
                    ></b-form-input>
                    <label for="last_name">Nachname</label>
                  </div>
                  <div class="input-field">
                    <b-form-input
                      id="textarea1"
                      type="email"
                      class="validate"
                      name="email"
                      v-model="contact.email"
                      required
                    ></b-form-input>
                    <label for="email">Email</label>
                  </div>
                  <div class="input-field">
                    <b-form-textarea
                      id="textarea1"
                      class="materialize-textarea"
                      name="message"
                      v-model="contact.message"
                      required
                    ></b-form-textarea>
                    <label for="textarea1">Nachricht</label>
                  </div>
                  <div class="contact-send">
                    <button class="btn waves-effect">Senden</button>
                  </div>
                </b-form>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { HTTP } from "../common/http-common.js";

export default {
  name: "Contact",
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      callFailed: false,

      contact: {
        first_name: "",
        last_name: "",
        email: "",
        message: "",
      },

      isSending: false,
    };
  },
  methods: {
    validateForm() {},

    onSubmit(e) {
      e.preventDefault();
      HTTP.post("/contact", this.contact)
        .then((response) => {
          console.log(response);
          this.clearForm();
          this.isSending = false;
          this.showSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.callFailed = true;
        });
    },

    clearForm() {
      for (let field in this.contact) {
        this.contact[field] = "";
      }
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },

    showSuccess() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style>
#contact {
  background-color: var(--background);
}
</style>
