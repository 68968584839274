<template>
  <div class="timeline-block">
    <div class="timeline-dot">
      <h6>{{ dotletter }}</h6>
    </div>
    <b-card class="timeline-content">
      <div class="card-content">
        <b-card-title>{{ title }}</b-card-title>
        <div class="timeline-info">
          <h6>
            <small>{{ company }}</small>
          </h6>
          <h6>
            <small>{{ time }}</small>
          </h6>
        </div>
        <p>
          {{ text }}
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ExperienceCard",
  props: ["dotletter", "title", "company", "time", "text"],
  data() {
    return {};
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
