<template>
  <section id="nav-section">
    <Navigation />
  </section>
</template>

<script>
import Navigation from "../components/Navigation.vue";

export default {
  name: "NavigationSection",
  components: {
    Navigation,
  },
};
</script>

<style>
#nav-section {
  background-color: var(--background);
}
</style>
