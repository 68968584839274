<template>
  <b-nav fill pills v-b-scrollspy>
    <b-nav-item @click="goto('skills')">Skill</b-nav-item>
    <b-nav-item @click="goto('education')">Education</b-nav-item>
    <b-nav-item @click="goto('experience')">Experience</b-nav-item>
    <b-nav-item @click="goto('interest')">Interests</b-nav-item>
    <b-nav-item @click="goto('contact')">Contact</b-nav-item>
  </b-nav>
</template>

<script>
export default {
  name: "NavigationBase",
  methods: {
    goto(id) {
      var element = document.getElementById(id);
      var top = element.offsetTop;
      window.scrollTo({ left: 0, top, behavior: "smooth" });
    },
  },
};
</script>

<style></style>
