<template>
  <div>
    <Header />
    <VCardHolder />
    <NavigationSection />
    <HomeView />
    <Education />
    <SkillView />
    <Experience />
    <InterestsView />
    <Contact />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import VCardHolder from "../components/VCardHolder.vue";
import NavigationSection from "../sections/NavigationSection";
import HomeView from "../sections/HomeView.vue";
import SkillView from "../sections/SkillView.vue";
import Experience from "../sections/ExperienceView.vue";
import Education from "../sections/Education.vue";
import InterestsView from "../sections/InterestsView.vue";
import Contact from "../sections/Contact.vue";

//import Footer from "../components/Footer.vue";

export default {
  name: "Index",
  components: {
    Header,
    VCardHolder,
    NavigationSection,
    HomeView,
    SkillView,
    Experience,
    Education,
    InterestsView,
    Contact,
  },
  methods: {
    goto(refName) {
      var element = this.$els[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
};
</script>

<style></style>
