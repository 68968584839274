<template>
  <footer>
    <b-container> </b-container>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>
