<template>
  <a @click="scrollTop" v-show="visible" class="bottom-right scrollup">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "TopPage",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    },
    scrollListener: function() {
      this.visible = window.scrollY > 150;
    },
  },
  mounted: function() {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function() {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style scoped>
.scrollup {
  color: #282828;
  height: 45px;
  width: 45px;
  cursor: pointer;
  font-size: 28px;
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  text-align: center;
  position: fixed;
  bottom: 21px;
  right: 18px;
  z-index: 5;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.btn {
  border-radius: 8px;
  color: #282828;
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
}

.btn-scrolltop {
  color: #282828;
  height: 45px;
  width: 45px;
  cursor: pointer;
  font-size: 40px;
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  text-align: center;
  position: fixed;
  bottom: 21px;
  right: 18px;
  z-index: 5;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  display: none;
  transition: 0.3s;
}
.bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}
</style>
