<template>
  <b-col sm="4" md="2" cols="6" class="box text-center">
    <div
      :class="[
        { 'interest-icon': index % 2 === 0 },
        { 'interest-icon-even': !(index % 2 === 0) },
      ]"
    >
      <!-- <b-icon scale="4" :icon="icon"></b-icon> -->
      <font-awesome-icon :icon="icon" />
      <span>{{ title }} </span>
    </div>
  </b-col>
</template
>

<script>
export default {
  name: "InterestIcon",
  props: ["title", "icon", "id", "index"],
};
</script>

<style scoped>
#interest .card .box .interest-icon,
#interest .card .box .interest-icon-even {
  display: block;
}
#interest .card .box .interest-icon {
  color: var(--foreground);
  background: var(--maincolor);
}
#interest .card .box .interest-icon-even {
  color: var(--maincolor);
}
#interest .card .box .interest-icon i,
#interest .card .box .interest-icon-even i {
  font-size: 2.2em;
  margin-top: 29px;
}
#interest .card .box .interest-icon svg,
#interest .card .box .interest-icon-even svg {
  font-size: 5em;
  padding: 5px;
}
#interest .card .box .interest-icon span,
#interest .card .box .interest-icon-even span {
  display: block;
  padding: 5px;
}
</style>