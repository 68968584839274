<template>
  <section v-scroll-reveal="{ delay: 500 }" id="home" class="section">
    <div class="section-title"></div>
    <b-container>
      <b-card body-class="text-center" v-if="!loading">
        <b-card-body>
          <b-card-text>{{ aboutstatementtext }}</b-card-text>
        </b-card-body>
        <div class="about-btn">
          <!-- BUTTONS BAUEN -->
          <!-- <a class=" btn-colored btn">DOWNLOAD</a> -->
        </div>
      </b-card>
    </b-container>
  </section>
</template>

<script>
import { HTTP } from "../common/http-common.js";

export default {
  name: "Home",
  data() {
    return {
      aboutstatementtext: "",
      loading: true,
    };
  },
  mounted() {
    HTTP.get("about")
      .then((response) => {
        this.aboutstatementtext = response.data.text;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
#home {
  background-color: var(--background);
}
.btn-colored {
  background-color: var(--maincolor) !important;
  color: var(--foreground) !important;
}
</style>
