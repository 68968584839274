<template>
  <section v-scroll-reveal="{ delay: 500 }" id="interest" class="section">
    <b-container>
      <div class="section-title">
        <h4 class="text-uppercase text-center">
          <font-awesome-icon :icon="'thumbs-up'" />
          Interests
        </h4>
      </div>
      <b-card no-body id="interest-card"  v-if="!loading">
        <div class="card-content text-center" v-if="!loadingText">
          {{ interestsText.text }}
        </div>
        <b-row no-gutters>
          <InterestIcon
            v-for="(interest, index) in display_interests"
            :key="interest.id"
            :title="interest.title"
            :icon="interest.icon"
            :id="interest.id"
            :index="index"
          />
        </b-row>
      </b-card>
    </b-container>
  </section>
</template>

<script>
import InterestIcon from "../components/InterestIcon.vue";
import { HTTP } from "../common/http-common.js";

export default {
  name: "InterestsView",
  components: {
    InterestIcon,
  },
  data() {
    return {
      interestsText: "NO TEXT FOUND",
      interests: [],
      display_interests: [],
      loading: true,
      loadingText: true,
    };
  },
  methods: {
    shortenIntersts() {
      if (this.interests.length > 6) {
        this.display_interests = this.interests.slice(0, 6);
      } else {
        this.display_interests = this.interests;
      }
    },
  },
  mounted() {
    HTTP.get("interests")
      .then((response) => {
        this.interests = response.data;
        this.shortenIntersts();
        this.loading=false;
      })
      .catch((error) => {
        console.log(error);
      });
    HTTP.get("interest-text")
      .then((response) => {
        this.interestsText = response.data;
        this.loadingText = false;
      })
      .catch((error) => console.log(error));
  },
};
</script>

<style></style>
