<template>
  <section
    v-scroll-reveal="{ delay: 500 }"
    id="v-card-holder"
    class="section v-card-holder"
  >
    <b-container>
      <b-card no-body id="v-card">
        <b-row no-gutters>
          <b-col id="profilepic" lg="4" md="5">
            <b-card-img src="@/assets/img/RICO13718_c.jpg"></b-card-img>
            <div class="slant"></div>
          </b-col>
          <b-col lg="8" md="7">
            <b-card-body>
              <div class="info-heading">
                <h4>Rico Faroß</h4>
                <h6>{{ headerText }}</h6>
              </div>
              <!-- <div class="infos"></div> -->
              <div class="links">
                <!-- xing -->
                <a
                  href="https://www.xing.com/profile/Rico_Faross"
                  class="mx-1 social"
                >
                  <font-awesome-icon :icon="['fab', 'xing-square']" size="3x" />
                </a>
                <!-- linkedin -->
                <a
                  href="https://www.linkedin.com/in/rico-faroß-731407163"
                  class="mx-1 social"
                >
                  <font-awesome-icon :icon="['fab', 'linkedin']" size="3x"
                /></a>
                <!-- github -->
                <a href="https://github.com/TheGlitchHam" class="mx-1 social">
                  <font-awesome-icon :icon="['fab', 'github-square']" size="3x"
                /></a>
                <!-- rund.tech -->
                <a href="https://rund.tech/" class="mx-1 social">
                  <font-awesome-icon :icon="['fas', 'h-square']" size="3x"
                /></a>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "VCardHolder",
  data() {
    return {
      headerText: "Software Entwickler",
    };
  },
};
</script>

<style>
.v-card-holder {
  overflow: visible;
  width: 100%;
  height: auto;
  margin-top: -194px;
}

#v-card-holder #v-card .links {
  max-width: 500px;
  margin-top: 12px;
  height: 100%;
}

.slant {
  top: 0;
  z-index: 1;
  position: absolute;
  border-style: solid;
  border-width: 400px 0 0 100px;
  border-color: var(--foreground) transparent;
  right: 0;
}

@media screen and (max-width: 767px) {
  #v-card-holder #v-card {
    position: relative;
    width: 100%;
    overflow: visible;
    z-index: 1;
    top: 34px;
  }
  #v-card-holder #v-card #profilepic {
    position: absolute;
    bottom: 75%;
    left: 0;
    right: 0;
    width: 150px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;
    background-color: transparent;
  }
  #v-card-holder #v-card #profilepic img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  #v-card-holder #v-card #profilepic .slant {
    display: none;
    opacity: 0;
    overflow: hidden;
  }
  #v-card-holder #v-card #profilepic .add-btn {
    display: none;
  }
  #v-card .info-heading {
    max-width: 100%;
    text-align: center;
    margin-top: 38px;
  }
}
</style>
