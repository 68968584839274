<template>
  <header id="home">
    <div class="header-background section"></div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style></style>
