<template>
  <section
    v-scroll-reveal="{ delay: 500 }"
    id="skills"
    class="section"
    ref="skills"
  >
    <div class="section-title">
      <h4 class="text-uppercase text-center">
        <font-awesome-icon :icon="'cogs'" />
        SKILLS
      </h4>
    </div>
    <b-container>
      <b-card id="skills-card" class="text-center" v-if="!loading">
        <div v-for="category in categories" :key="category.name">
          <b-card-title class="skills-title">{{ category.name }}</b-card-title>

          <div
            v-for="skill in category.skills"
            :key="skill.title"
            class="skillbar"
          >
            <div class="skillbar-title">
              <span>{{ skill.title }}</span>
            </div>
            <b-progress :value="skill.progress" :max="100" class="mb-3">
            </b-progress>
            <div class="skill-bar-level">{{ skill.level }}</div>
          </div>
        </div>
      </b-card>
    </b-container>
  </section>
</template>

<script>
import { HTTP } from "../common/http-common.js";

export default {
  name: "SkillView",
  data() {
    return {
      categories: [],
      loading: true,
    };
  },
  methods: {
    extractCategories(skills) {
      skills.forEach((skill) => {
        var newCategory = { name: skill.category, skills: [skill] };
        var found = false;
        this.categories.forEach((category) => {
          if (category.name == newCategory.name) {
            found = true;
            category.skills.push(skill);
          }
        });
        if (!found) {
          this.categories.push(newCategory);
        }
      });
    },
  },

  mounted() {
    HTTP.get("skills")
      .then((response) => {
        this.extractCategories(response.data);
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
#skill {
  background-color: var(--background);
}
</style>
